import * as React from 'react';
import {Stack} from "@mdi/react";
import {Box} from "@mui/system";

const textHearing = "<p >Неречевые звуки играют важную роль как в речевом развитии, так и в каждодневной жизни. Различение неречевых звуков помогает установить связь между органом слуха и органом речи, а также воспринимать их как сигналы, указывающие на приближение или удаление предметов или живых существ. Правильное определение направления, откуда идет звук, помогает ориентироваться в пространстве, определять свое местонахождение, направление движения, и это также важно для речевого развития.&nbsp;</p>\n" +
    "<p >В речевом развитии неречевой слух играет существенную роль, но малыш до сих пор может испытывать трудности не только в дифференциации звуков, но и в их осмыслении. Пришло время поработать в этом направлении.</p>\n" +
    "<p >На протяжении всего курса Вам будут приходить ссылки на звуковые ролики для тренировки неречевого слуха. В связи с последними событиями и авторскими правами в разных странах, самым удобным способом стало использование платформы YouTube.</p>\n" +
    "<p >Прошу вас не обращать внимание на названия роликов, они нужны лишь на один день для работы со слухом. Если по каким-либо причинам вам неудобны предоставленные звуки, вы можете найти другие похожие варианты.</p>\n" +
    "<p >Итак, вот как мы будем работать:</p>\n" +
    "<div >\n" +
    "<ul >\n" +
    "<li  aria-level=\"1\" style='font-weight: bold; margin-bottom: 6px'> Включите ролик на МИНИМАЛЬНОЙ громкости. Источник звука не должен быть виден (разместите телефон, например, на полке, шкафу или под кроватью).</li>\n" +
    "<li  aria-level=\"1\" style='font-weight: bold; margin-bottom: 6px'> Периодически привлекайте внимание малыша к определенным звукам. Например, скажите: \"Ты слышишь этот колокольчик? А вот опять\". Двигайтесь вместе с ребенком, то удаляясь, то приближаясь к источнику звука. Обратите внимание малыша на изменение тембра звука и другие характеристики.&nbsp;</li>\n" +
    "<li  aria-level=\"1\" style='font-weight: bold; margin-bottom: 6px'> Эмоционально поощряйте и восхищайтесь его успехами, говоря, например: \"Ну надо же, ты услышал!&rdquo;</li>\n" +
    "<li  aria-level=\"1\" style='font-weight: bold; margin-bottom: 12px'> Включившись в процесс обязательно отмечать похожие звуки в повседневной жизни, например, во время прогулки или игры.</li>\n" +
    "</ul>\n" +
    "</div>\n" +
    "<p >В зависимости от места игровой локации вы будете получать различные звуки. Необязательно придерживаться определенного времени или продолжительности просмотра ролика. Например, если вы оставили гаджет на кухне, проходя мимо, удивитесь и скажите: \"Ты слышишь?\". Затем занимайтесь обычными делами.&nbsp;</p>\n" +
    "<p >Ожидается, что слуховое внимание к неречевым звукам у малыша начнет улучшатся примерно через 2-3 недели ежедневных занятий.</p>\n" +
    "<p >К сожалению, в представленных роликах может быть реклама, и если у вас нет премиум-аккаунта, вы можете реагировать на изменение звуковой последовательности вместе с малышом (и это тоже будет тренировать слуховое внимание).</p>\n" +
    "<p >Вы же понимаете, что проводя такие занятия в конечном итоге вы тренируете слуховую сосредоточенность, внимание к произносимым словам и звукам и более легкое восприятия разнообразия окружающей речи.</p>"

export default function SpeakGuide() {
    return (<Box sx={{marginY: 3, paddingX: 4,  fontSize: {md: 15, sm: 14, xs:12 }}} >
        <Box sx={{my:4, textAlign: "center"}} display="flex" justifyContent="center">
            <h2 >
                Как работать с неречевым слухом
            </h2>
        </Box>
        <Box dangerouslySetInnerHTML={{__html: textHearing}}>
        </Box>
    </Box>
    );
}
