import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import "./MainTeam.css"
import {Box} from "@mui/material";

import Grid from "@mui/material/Grid";

import ImageLeft from "../../img/avatar-900.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";




 const   profile =  [
   "Мама трех успешных сыновей, врач-педиатр с дипломом MBA и сертифицированный эксперт по современным методам развития мозга, получивший сертификат в Массачусетском университете",
   "В 2023 году я успешно завершила программу по семейной психологии, что позволяет мне более глубоко понимать потребности родителей и детей в вопросах развития и воспитания",
    "Мои методы основаны на научных исследованиях и личном опыте работы с тысячами детей и их родителями. Верю, что каждый ребенок уникален и заслуживает индивидуального подхода для раскрытия своего потенциала",
    "Разработала уникальные приложения и чат-боты, помогающие родителям отслеживать развитие детей и получать рекомендации по уходу и развитию",
    "Присоединяйтесь к более чем 500 000 подписчикам в социальных сетях, чтобы получать эксклюзивный контент и советы по развитию детей от рождения до трех лет"
]

const defaultTheme = createTheme();

export default function FirstElenaTeam({}) {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            {/* Hero unit */}
            <Container component="section">

                <Grid container sx={{ marginTop: {md: 2, sm: 1, xs: 1}, }}>
                    <Grid item xs={12} md={8}
                          sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',   marginTop: {md: 0, sm: 3, xs: 2}, order: {md: 1, sm: 2, xs: 2}}}>
                        {profile.map((item, index) => (
                                <Grid container key={index} sx={{marginBottom:1}} className={"cardTitleOne size-common afterLine"}>
                                    <Grid item xs={12} display={"flex"} justifyContent={"flex-start"} alignItems={"center"} sx={{paddingLeft:1}}>
                                        {item}
                                    </Grid>
                                </Grid>
                            )
                        )
                        }
                    </Grid>
                    <Grid item xs={12} md={4} sx={{
                        display: "flex",
                        justifyContent: {md: 'flex-end', sm: 'center', xs: 'center'},
                        alignItems: {md: 'flex-end', sm: 'center', xs: 'center'},
                        marginY: {md: 0, sm: 3, xs: 3},
                        order: {md: 2, sm: 1, xs: 1},
                        flexDirection: "column"
                    }}>
                        <Box
                            component="img"
                            src={ImageLeft}
                            alt="Главная картинка"
                            sx={{
                                width: '100%',
                                maxWidth: 400
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
