import React from 'react';
import './MainTeam.css';
import Footer from "../Footer/Footer"
import LineBlock from "../SecondScreen/LineBlock"
import Citation from "../Citation/Citation";
import FirstElenaTeam from "./FirstElenaTeam"
import Slider from "../Sliders/Sliders";
import ElenaTeamService from "./ElenaTeamService";
const images = [
    {
        label: 'Педиатр (1991 год)',
        imgPath: require("./diploma/diplom_4.png")
    },
    {
        label: 'Семейный психолог (2023 год)',
        imgPath: require("./diploma/diplom_6.png")
    },
    {
        label: 'Педиатр (2021 год)',
        imgPath: require("./diploma/diplom_3.png")
    },
    {
        label: 'МВА (2012)',
        imgPath: require("./diploma/diplom_2.png")
    },
    {
        label: 'Специалист по COVID-19 (2020)',
        imgPath: require("./diploma/diplom_5.png")
    }
];

const firstCitation = '\"Как специалист, четко осознаю, что родители часто сталкиваются с недостатком информации, страхами и ' +
    'сомнениями в своих способностях воспитывать и ухаживать за своим малышом. Моя задача - помочь вам в решении этих проблем\"'

const secondCitation = "\" В целом, моя цель - помочь вам стать уверенными и успешными родителями, обеспечив своим малышам гармоничное и здоровое развитие. Готова предоставить вам необходимые знания и поддержку на каждом этапе родительства, чтобы вы могли принимать обоснованные решения, повышать свою компетентность и наслаждаться процессом воспитания своих детей. Не стесняйтесь обращаться ко мне за помощью и советами - всегда готова поделиться своим опытом и знаниями, чтобы помочь вам достичь успеха в родительстве \""
export default function MainTeam() {
    return (
        <div>
            <LineBlock title={"Корнева Елена Викторовна"}/>
            <FirstElenaTeam/>
            <Citation title={firstCitation}/>
            <ElenaTeamService />
            <Citation title={secondCitation}/>
            <LineBlock title={"Образование"}/>
            <Slider imagesFrom={images} maxWidth={500}/>
            <Footer/>
        </div>
    );
}


