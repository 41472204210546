import * as React from 'react';
import dayjs from 'dayjs';
import "./Schedule.css"
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {Box} from "@mui/system";
import ModalInput from "../ModalComponent/ModalInput";
import {useState} from "react";

const DateSchedule = [
    {
        day: "2024-02-20",
        dayOfWeek: "Вторник",
        time: ["16-00"],
        checkTime: [true]
    },
    {
        day: "2024-02-21",
        dayOfWeek: "Среда",
        time: ["18-00"],
        checkTime: [true]
    },
    {
        day: "2024-02-22",
        dayOfWeek: "Четверг",
        time: ["10-00", "12-00", "16-00", "18-00"],
        checkTime: [true, true, true, true]
    },
    {
        day: "2024-02-24",
        dayOfWeek: "Суббота",
        time: ["16-00", "18-00"],
        checkTime: [true, true]
    },
    {
        day: "2024-02-26",
        dayOfWeek: "Понедельник",
        time: ["10-00", "12-00", "14-00"],
        checkTime: [true, true, true]
    },
    {
        day: "2024-02-27",
        dayOfWeek: "Вторник",
        time: ["15-00", "17-00", "19-00"],
        checkTime: [true, false, true]
    },
    {
        day: "2024-02-28",
        dayOfWeek: "Среда",
        time: ["10-00", "12-00"],
        checkTime: [true, true]
    },
    {
        day: "2024-02-29",
        dayOfWeek: "Четверг",
        time: ["17-00", "19-00"],
        checkTime: [true, true]
    },
    {
        day: "2024-03-01",
        dayOfWeek: "Пятница",
        time: ["10-00", "12-00"],
        checkTime: [true, true]
    },
    {
        day: "2024-03-01",
        dayOfWeek: "Суббота",
        time: ["17-00", "19-00"],
        checkTime: [true, true]
    }
]



export default function DatePickerValue() {
    const [value, setValue] = React.useState(dayjs());
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [dateToModal, setDateToModal] = useState('');
    const closeModal = () => {
        setModalIsOpen(false);
    }
    const handleModalOpen = (day, time, check) =>{
        setModalIsOpen(true);
        setDateToModal([day, time, check])
    }

    return (
        <Box className={"box-center"}>
            {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
            {/*    <DemoContainer components={['DatePicker', 'DatePicker']}>*/}
            {/*        <DatePicker*/}
            {/*            label="Выберите дату"*/}
            {/*            value={value}*/}
            {/*            onChange={(newValue) => setValue(newValue)}*/}
            {/*        />*/}
            {/*        <TimePicker label="Выберите время" defaultValue={value}/>*/}
            {/*    </DemoContainer>*/}
            {/*</LocalizationProvider>*/}
            {DateSchedule.map((element, index) =>
                <Box sx={{border: "1px solid #666666", width: 360, marginBottom: 2}} key={index}>
                    <Box sx={{padding: 2, textTransform: "uppercase"}}>Дата {element.day}: {element.dayOfWeek}</Box>
                    {element.time.map((elementTime, indexTime) =>
                        <Box sx={{padding: 2, textTransform: "uppercase"}} key={indexTime}
                             className={element.checkTime[indexTime] ? "orderFree" : "orderBusy"}
                             onClick={() => handleModalOpen(element.day, elementTime, indexTime)}
                        >{elementTime}</Box>
                    )}
                </Box>
            )}
            <ModalInput isOpen={modalIsOpen} closeModal={closeModal} dateToModal={dateToModal}/>
        </Box>
    );
}
