import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TelegramIcon from '@mui/icons-material/Telegram';
import Link from "@mui/material/Link";

const defaultTheme = createTheme();

export default function CallOrder({title}) {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            {/* Hero unit */}
            <Container sx={{pt: 4, pb: 2}}>
                <Grid container className={"box-center"}>
                    <Grid item md={6} sm={11} xs={11} display={'flex'} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                        <Box className={"top-mainSubTitle"} sx={{marginY:2}}>Стоимость 9000 рублей</Box>
                        <Link href={"https://payform.ru/503Br3L/"}  target={"_blank"} className={"btn-call"}  sx={{ display: "flex", justifyContent: 'center', alignItems: "flex-end", marginTop: {md: 2, sm: 3, xs: 3}}}>
                            <Box className={"top-mainSubTitle"}>Оплатить</Box>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>

                    </Grid>
                    <Grid item>

                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
