import './App.css';
import * as React from 'react';
import {Route, Routes} from 'react-router-dom'
import Main from "./components/main/Main"
import Privacy from "./components/Legal/Privacy";
import Terms from "./components/Legal/Terms";
import Success from "./components/BackPayment/Success";
import Failed from "./components/BackPayment/Failed";
import {StyledEngineProvider} from "@mui/material";
import DrawerLeft from "./components/Drawer/anotherDrawer"
import MainCourses from "./components/MainCourses/MainCourses"
import FreeLessons from "./components/FreeLessons/FreeLessons";
import MainConsultation from "./components/Consultation/MainConsultation";
import MainTeam from "./components/Team/MainTeam";
import Schedule from "./components/Schedule/Schedule";
import SpeakGuide from "./components/speakCourse/speakGuide";


function App() {
    return (
        <div className="App">
            <React.StrictMode>
                <StyledEngineProvider injectFirst>
                    <DrawerLeft/>
                </StyledEngineProvider>
            </React.StrictMode>
            <Routes>
                <Route index element={<Main />}/>
                <Route path={'course'} element={<MainCourses/>}/>
                {/*<Route path={'mini'} element={<MiniCourses/>}/>*/}
                <Route path={'free_lessons'} element={<FreeLessons/>}/>
                <Route path={'consultation'} element={<MainConsultation/>}/>
                <Route path={"schedule" } element={<Schedule/>}/>
                <Route path={'team'} element={<MainTeam/>}/>
                <Route path={'privacy'} element={<Privacy />}/>
                <Route path={'terms'} element={<Terms />}/>
                <Route path={'success'} element={<Success />}/>
                <Route path={'failed'} element={<Failed />}/>
                <Route path={'rechevoykurs/instrukciya'} element={<SpeakGuide />}/>
            </Routes>
        </div>
    );
}

export default App;
