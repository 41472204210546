import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import "./MiniCourses.css"
import AlertDialogSlide from "../Dialog/Dialog"
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import rb from "../../img/right-bottom.svg";
import Link from "@mui/material/Link";
import ListAltIcon from '@mui/icons-material/ListAlt';
import Grid from "@mui/material/Grid";


export default function MiniCourses() {
    const descriptionCourse = [
        {
            index: 0,
            linkToTilda: 'https://t.me/korneva_gorshok_bot',
            title: 'ПРИУЧАЕМ К ГОРШКУ ЛЕГКО И ПРОСТО',
            subTitle: 'Приучить малыша к горшку легче, чем кажется! В этом мини курсе я собрала все, что вам необходимо для быстрого приучения к горшку - от рабочих методик до всех важных нюансов, без которых не будет результата.<BR/><BR/> <h3 style="color: #010f2c">Что вас ждет в мини курсе?</h3> ',
            img: require("../../img/emo-cards/emo-img-3.png"),
            description:
                '<p>✅ Подробный и понятный разбор физиологии и психологии малыша: не понимая этих процессов, вы просто не сможете получить результат</p>\n' +
                '<p>✅ 7+ методик приучения к горшку на выбор, с подробными пошаговыми гайдами и алгоритмами. Вы сами выбираете вариант, который подойдет вам и ребенку</p>\n' +
                '<p>✅ И - самое главное - пошаговый разбор ПОДГОТОВИТЕЛЬНОГО этапа, без которого все ваши действия будут неэффективными</p>\n' +
                '<p>Этот курс подходит для малышей любого возраста, начиная с 2-3 недельного (для таких крох у меня есть методика, которая позволит значительно сэкономить на подгузниках - проверено мной лично). А если ваш ребенок постарше - на выбор у вас будет много вариантов: вы точно найдете свой.</p>\n' +
                '<p><br/> Если вы будете четко следовать алгоритму и всем рекомендациям, у вас просто не будет шансов не получить результат.</p>\n' +
                '<p><br/> Доступ к курсу - сразу после оплаты, в отдельном Telegram канале, который остается у вас навсегда</p>\n' +
                '<p>Приобрести курс можно через бот по ссылке (просто перейдите в бот и следуйте инструкциям)</p>' +
                '<p><br/></p>'+
                '<h3 style="color: #010f2c"><a TARGET="_blank" href="https://t.me/korneva_gorshok_bot">ПРИОБРЕСТИ КУРС >> </a></h3>'+
                '<p><br/></p>'+
                'Бесплатный урок «Как приучить к горшку, не тратя месяцы и годы»'+
                '<p><br/></p>'+
                '<h3 style="color: #010f2c"><a TARGET="_blank" href="https://t.me/korneva_gorshok_bot">ПОЛУЧИТЬ УРОК >> </a></h3>'
        },
        {
            index: 1,
            linkToTilda: 'https://t.me/helen_korneva_lazy_bot',
            title: 'ЛЕНИВАЯ МАМА - ИДЕАЛЬНАЯ МАМА: КАК ПРИУЧИТЬ РЕБЕНКА К САМОСТОЯТЕЛЬНОСТИ',
            subTitle: 'Вы легко можете научить ребенка самостоятельно есть, играть и убирать за собой игрушки, сэкономив себе море времени и сил. Более того, вам не нужно повторять ребенку одно и то же по десять раз, чтобы он вас услышал. Но малыш не научится всему этому сам.<br/>Благодаря мини курсу, вы сможете легко и просто приучить своего ребенка быть самостоятельным, используя мои четкие алгоритмы и инструкции.<BR/><BR/> <h3 style="color: #010f2c">Что вас ждет в мини курсе?</h3> ',
            img: require("../../img/emo-cards/emo-img-3.png"),
            description:
                '<p> ✅Вводное видео, где я подробно рассказываю, почему обучая ребенка самостоятельности вы делаете огромный вклад в его будущее (и это помимо экономии вашего времени и нервов!)</p>\n' +
                '<p> ✅Подробный и понятный разбор алгоритмов, как научить ребенка:</p>\n' +
                '<p> ✔Самостоятельно убирать игрушки</p>\n' +
                '<p> ✔Самостоятельно есть</p>\n' +
                '<p> ✔Самостоятельно играть</p>\n' +
                '<p> ✔ Слушать вас с первого раза</p>\n' +
                '<p> ✅Для каждого алгоритма - подкаст с разбором всех нюансов методики и емкий гайд-конспект с самым важным, чтобы необходимая информация всегда была у вас под рукой</p>\n' +
                '<p> ✅Дополнительный лайфхак, с которого можно начать приучение к самостоятельности</p>\n' +
                '<p>Если вы будете четко следовать алгоритмам и всем рекомендациям, вы получите результат.</p>\n' +
                '<p>А время и силы, которые вы потратите на приучение малыша к самостоятельности с помощью алгоритмов из курса - это капля в море по сравнению с тем, сколько времени и сил вы будете ежедневно и на протяжении не одного года тратить на то, чтобы продолжать делать за ребенка простые вещи.</p>\n' +
                '<p>Доступ к курсу - сразу после оплаты, в отдельном Telegram канале, который остается у вас навсегда</p>'+
                '<p>Приобрести курс можно через бот по ссылке (просто перейдите в бот и следуйте инструкциям)</p>' +
                '<p><br/></p>'+
                '<h3 style="color: #010f2c"><a TARGET="_blank" href="https://t.me/helen_korneva_lazy_bot">ПРИОБРЕСТИ КУРС >> </a></h3>'+
                '<p><br/></p>'+
                'Бесплатный урок «5 принципов ленивой мамы, или как не задолбаться в материнстве»'+
                '<p><br/></p>'+
                '<h3 style="color: #010f2c"><a TARGET="_blank" href="https://t.me/+UAK-ppZyAVQ1OTcy">ПОЛУЧИТЬ УРОК >> </a></h3>'
        },
        {
            index: 2,
            linkToTilda: 'https://t.me/helen_korneva_isteriki_bot',
            title: 'ИСТЕРИКИ В ПРОШЛОМ. КАК НАУЧИТЬ РЕБЕНКА СПРАВЛЯТЬСЯ С ЭМОЦИЯМИ',
            subTitle: '<p>Вам не обязательно терпеть постоянные крики и ждать, пока малыш &laquo;перерастет&raquo;. Истерики - это не так сложно, как кажется, если точно знать, как действовать в каждом конкретном случае.</p>\n' +
                '<p>Если вы хотите научиться эффективно справляться с эмоциями малыша, зная, что вы все делаете правильно - я жду вас на мини-курсе &laquo;Как работать с истериками&raquo;</p>\n' +
                '<p>Вы получите подробные алгоритмы в формате &laquo;бери и делай&raquo; для каждого вида истерик, которые позволят вам легко справиться с всплеском эмоций вашей крохи, совершая только те действия, которые реально работают.</p><BR/><h3 style="color: #010f2c">Что вас ждет в мини курсе?</h3> ',
            img: require("../../img/emo-cards/emo-img-3.png"),
            description:
                '<p>✅Подробная инструкция, как работать с курсом, чтобы получить результат</p>\n' +
                '<p>✅Базовая информация, которую нужно знать при работе с ЛЮБЫМИ истериками (без этого не будет результата)</p>\n' +
                '<p>✔Контейнирование<br />✔Базовый алгоритм работы с истериками<br />✔Все ли слезы наносят вред, или почему ребенку необходимо плакать?</p>\n' +
                '<p>✅Подробный пошаговый алгоритм действий и разбор того, что на самом деле происходит с ребенком по ВСЕМ популярным типам истерик (как себя вест, как сделать так, чтобы такая истерика не повторялась из раза в раз, и как не усугубить ситуацию)</p>\n' +
                '<p>✔Истерики при ушибах и синяках<br />✔Манипулятивная истерика/коммуникативная истерика<br />✔Истерики, когда пора уходить<br />✔Истерики на детской площадке - ребенок не получил желаемое<br />✔Истерики на детской площадке - конфликт<br />✔Истерики, переходящие в аффективно-респираторные приступы<br />✔Истерики из-за недостатка внимания<br />✔Истерики из-за невозможности изменить ситуацию<br />✔Истерики из-за запретов и отказов. Бонус! Учимся запрещать правильно<br />✔Истерики из-за того, что что-то не получается<br />✔Истерики при одевании<br />✔Истерики ВСЕГДА бесят и раздражают. Как себе помочь?<br />✔История из практики - бабушка и истерики</p>\n' +
                '<p>✅Гайды-памятки, чтобы важная информация и алгоритмы всегда были у вас под рукой</p>\n' +
                '<p>Ваша задача - просто пошагово следовать алгоритмам, и больше ни одна истерика не застанет вас врасплох.</p>\n' +
                '<p>Доступ к курсу - сразу после оплаты, в отдельном Telegram канале, который остается у вас навсегда</p>\n' +
                '<p>Приобрести курс можно через бот (просто перейдите в бот и следуйте инструкциям)</p>' +
                '<p><br/></p>'+
                '<h3 style="color: #010f2c"><a TARGET="_blank" href="https://t.me/helen_korneva_isteriki_bot">ПРИОБРЕСТИ КУРС >> </a></h3>'+
                '<p><br/></p>'+
                'Бесплатный урок «Истерики не проблема! Эффективная система работы с истериками» + пошаговый алгоритм, как увести ребенка с площадки без криков и слез'+
                '<p><br/></p>'+
                '<h3 style="color: #010f2c"><a TARGET="_blank" href="https://t.me/+b7CYi2PjqsBmZmVi">ПОЛУЧИТЬ УРОК >> </a></h3>'
        }
    ]
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState('');
    const closeDialog = () => {
        setDialogOpen(false);
    };
    const openDialog = (text) => {
        setDialogOpen(true);
        setDialogText(text)
    }
    return (
        <Container className={"box-center"}>
            {descriptionCourse.map((element, index) =>
                <Card className={"card-timeline"}>
                    <CardContent sx={{position: "relative"}}>
                        <Box sx={{display: "flex"}} justifyContent={"flex-start"} alignItems={"flex-start"}
                             flexDirection={"column"}>
                            <Typography variant="body2" color="#010f2c" className={"title-courses"}>
                                {element.title}
                            </Typography>
                            <Typography variant="h5" component="div" className={"h5-size"}
                                        dangerouslySetInnerHTML={{__html: element.subTitle}}/>
                        </Box>
                        <Box
                            component="img"
                            src={rb}
                            alt="Главная картинка"
                            className={"corner-timeline"}

                        />
                    </CardContent>
                    <CardActions sx={{display: "flex", justifyContent: 'center'}}>
                        <Button className={'timeline-button'}
                                onClick={() => openDialog(element.description)}>
                            Подробнее</Button>
                        <a href={element.linkToTilda} target='_blank'>
                            <Button className={'timeline-button'}>
                                Перейти</Button>
                        </a>
                    </CardActions>
                </Card>
            )
            }
            <AlertDialogSlide isOpen={dialogOpen} closeDialog={closeDialog} text={dialogText}/>
        </Container>
    );
}
