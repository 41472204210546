import React from 'react';
import Modal from 'react-modal';
import {Box} from '@mui/system';
import './Modal.css';

Modal.setAppElement('#root');

export default function ModalInput({isOpen, closeModal, dateToModal}) {
    const modalStyles = {
        content: {
            display: 'flex',
            inset: 9,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '96%', // Set the width as per your requirement
            margin: ' 16px auto 0 auto',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
    };
    function formatDate(inputDate) {
        // Разбиваем входную дату на части
        const parts = inputDate.split('-');

        // Получаем значения для дня, месяца и года
        const year = parts[0];
        const month = parseInt(parts[1], 10);
        const day = parseInt(parts[2], 10);

        // Создаем объект даты для обработки
        const date = new Date(year, month - 1, day);

        // Массивы для текстовых значений месяцев и окончаний для дней
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        const dayEndings = ['года', 'года', 'года', 'года', 'года', 'лет', 'лет', 'лет', 'лет', 'лет'];

        // Форматируем дату
        return `${day} ${months[date.getMonth()]} ${year} ${dayEndings[parseInt(year.substr(-1), 10)]}`;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            style={modalStyles}
        >
            <Box className={'modal-box'}>
                <Box sx={{color: "#666666", marginTop: 6, textTransform: "uppercase", backgroundColor: "white", padding:3}}>
                    <h3>Вы выбрали {formatDate(dateToModal[0])}
                    </h3>
                    <h4>Время {dateToModal[1]}
                    </h4>
                    <h4 className={"mt-4"}>Ввведите ваши данные для регистрации
                    </h4>
                </Box>
                <button className={'btn-close'} onClick={closeModal}>
                    Закрыть
                </button>
            </Box>
        </Modal>
    );
}

