import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Terms from "./Terms";



const defaultTheme = createTheme();

export default function Privacy({title}) {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            {/* Hero unit */}
            <Container disableGutters maxWidth="md" sx={{ pt: 4, pb: 2 }} className={"terms"}>
            <h2 style={{textAlign: "center"}}><strong>Политика конфиденциальности</strong></h2>
                 <p>&nbsp;</p>
                <p>Настоящая Политика конфиденциальности персональных данных (далее &ndash;&nbsp;<strong>Политика конфиденциальности</strong>) действует в отношении всей информации, которая размещена на&nbsp; сайтах и ботах&nbsp;&nbsp;<strong>врача и&nbsp; психолога Елены Корневой,</strong>&nbsp;(далее &ndash;&nbsp;<strong>Ресурсы Елены Корневой</strong>&nbsp;), расположенных на доменных именах&nbsp;<strong data-redactor-tag="strong">elenakorneva.ru, elenakorneva.site</strong>&nbsp;(а также их субдоменах), а также в боте&nbsp;<strong>HelenBot</strong>, может получить о Пользователе во время использования вышеуказанных сайтов и ботов,&nbsp; его программ и его продуктов.</p>
                <p>&nbsp;</p>
                <p>1. Определение терминов</p>
                <p>&nbsp;</p>
                <p>1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>
                <p>1.1.1. &laquo;<strong data-redactor-tag="strong">Администрация сайтов (ботов)</strong>&raquo; ( далее &ndash;&nbsp;<strong>Администрация</strong>) &ndash; уполномоченные сотрудники на управление сайтами и ботами&nbsp;<strong data-redactor-tag="strong">врача и психолога Елены Корневой</strong>, которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                <p>1.1.2.&nbsp;<strong>&laquo;Персональные данные&raquo;</strong>&nbsp;- любая информация, относящаяся к прямо или косвенно определенному
                    или определяемому физическому лицу (субъекту персональных данных).</p>
                <p>1.1.3.<strong>&nbsp;&laquo;Обработка персональных данных&raquo;</strong>&nbsp;- любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                <p>1.1.4.&nbsp;<strong>&laquo;Конфиденциальность персональных данных&raquo;</strong>&nbsp;- обязательное для соблюдения Администрацией или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
                <p>1.1.5. &laquo;<strong>Ресурсы Елены Корневой</strong>&raquo; - это совокупность связанных между собой веб-страниц,
                    размещенных в сети Интернет по уникальным адресам (URL):&nbsp;<strong data-redactor-tag="strong">elenakorneva.ru</strong>,
                    &nbsp;<strong data-redactor-tag="strong">elenakorneva.site&nbsp;</strong>, а также их субдоменах, а также&nbsp;<strong>Helenbot.</strong></p>
                <p>1.1.6.&nbsp;<strong>&laquo;Субдомены&raquo;</strong>&nbsp;- это страницы или совокупность страниц,
                    расположенные на доменах третьего уровня, принадлежащие Ресурсам Елены Корневой, а также другие временные страницы, внизу который указана контактная информация Администрации.</p>
                <p>1.1.7. &laquo;Пользователь&nbsp;<strong>Ресурсов Елены Корневой</strong>&nbsp;&raquo; (далее&nbsp;<strong>Пользователь)</strong>&nbsp;&ndash; лицо, имеющее доступ к&nbsp;<strong>Ресурсам Елены Корневой</strong>, посредством сети Интернет и использующее информацию, материалы и продукты&nbsp;<strong>Ресурсов Елены Корневой</strong>.</p>
                <p>1.1.8.&nbsp;<strong>&laquo;Cookies&raquo;</strong>&nbsp;&mdash; небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
                <p>1.1.9.&nbsp;<strong>&laquo;IP-адрес&raquo;</strong>&nbsp;&mdash; уникальный сетевой адрес узла в компьютерной сети, через который Пользователь получает доступ на&nbsp;<strong>Ресурсы Елены Корневой</strong>.</p>
                <p>&nbsp;</p>
                <p>2. Общие положения</p>
                <p>&nbsp;</p>
                <p>2.1. Использование <strong>Ресурсов Елены Корневой</strong>&nbsp; Пользователем означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
                <p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование <strong>Ресурсов Елены Корневой</strong>&nbsp;.</p>
                <p>2.3. Настоящая Политика конфиденциальности применяется к <strong>Ресурсам Елены Корневой</strong> . <strong>Ресурсы Елены Корневой</strong>&nbsp; не контролируют и не несут ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на <strong>Ресурсах Елены Корневой</strong>.</p>
                <p>2.4. Администрация не проверяет достоверность персональных данных, предоставляемых Пользователем.</p>
                <p>&nbsp;</p>
                <p>3. Предмет политики конфиденциальности</p>
                <p>&nbsp;</p>
                <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации при регистрации на <strong>Ресурсах Елены Корневой </strong>или при подписке на информационную e-mail рассылку.</p>
                <p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения форм на <strong>Ресурсах Елены Корневой </strong>и включают в себя следующую информацию:</p>
                <p>3.2.1.&nbsp; фамилию, имя, отчество Пользователя;</p>
                <p>3.2.2. фамилию, имя, отчество детей Пользователя, его дата рождания;</p>
                <p>3.2.3.&nbsp; метрические и другие данные ребенка, необходимые для прохождения тестов&nbsp;</p>
                <p>3.2.4. контактный телефон Пользователя;</p>
                <p>3.2.5. адрес электронной почты (e-mail)</p>
                <p>3.2.6. место жительство Пользователя (при необходимости)</p>
                <p>3.2.7. фотографии (при необходимости)</p>
                <p>3.3. <strong>Ресурсы&nbsp; Елены Корневой </strong>&nbsp;защищают Данные, которые автоматически передаются при посещении страниц и ботов:
                    <br />- IP адрес;
                    <br />- ID bota:
                    <br />- информация из cookies;
                    <br />- информация о браузере
                    <br />- время доступа;<br />- реферер (адрес предыдущей страницы).</p>
                <p>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта , требующим авторизации.</p>
                <p>3.3.2. <strong>Ресурсы&nbsp; Елены Корневой&nbsp; </strong>осуществляют сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью предотвращения, выявления и решения технических проблем.</p>
                <p>3.4. Любая иная персональная информация неоговоренная выше (история посещения, используемые браузеры, операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. настоящей Политики конфиденциальности.</p>
                <p>&nbsp;</p>
                <p>4. Цели сбора персональной информации пользователя</p>
                <p>&nbsp;</p>
                <p>4.1. Персональные данные Пользователя Администрация может использовать в целях:</p>
                <p>4.1.1. Идентификации Пользователя, зарегистрированного на <strong>Ресурсах&nbsp; Елены Корневой&nbsp;</strong> для его дальнейшей авторизации.</p>
                <p>4.1.2. Предоставления Пользователю доступа к персонализированным данным на <strong>Ресурсах&nbsp; Елены Корневой </strong>.</p>
                <p>4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования <strong>Ресурсов&nbsp; Елены Корневой&nbsp;</strong> обработки запросов и заявок от Пользователя.</p>
                <p>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
                <p>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
                <p>4.1.6. Создания учетной записи для использования частей <strong>Ресурсов&nbsp; Елены Корневой </strong>, если Пользователь дал согласие на создание учетной записи.</p>
                <p>4.1.7. Уведомления Пользователя по электронной почте.</p>
                <p>4.1.8. Предоставления Пользователю эффективной технической поддержки при возникновении проблем, связанных с использованием <strong>Ресурсов&nbsp; Елены Корневой </strong>.</p>
                <p>4.1.9. Предоставления Пользователю с его согласия специальных предложений, новостной рассылки и иных сведений от имени <strong>Ресурсов&nbsp; Елены Корневой </strong>.</p>
                <p>&nbsp;</p>
                <p>5. Способы и сроки обработки персональной информации</p>
                <p>&nbsp;</p>
                <p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
                <p>5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
                <p>5.3. При утрате или разглашении персональных данных Администрация вправе не информировать Пользователя об утрате или разглашении персональных данных.</p>
                <p>5.4. Администрация принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
                <p>5.5. Администрация совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
                <p>&nbsp;</p>
                <p>6. Права и обязанности сторон</p>
                <p>&nbsp;</p>
                <p>6.1. Пользователь вправе:</p>
                <p>&nbsp;</p>
                <p>6.1.1. Принимать свободное решение о предоставлении своих персональных данных, необходимых для использования <strong>Ресурсов&nbsp; Елены Корневой </strong>&nbsp;и давать согласие на их обработку.</p>
                <p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
                <p>6.1.3. Пользователь имеет право на получение у Администрации информации, касающейся обработки его персональных данных, если такое право не ограничено в соответствии с федеральными законами. Пользователь вправе требовать от Администрации уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав. Для этого достаточно уведомить Администрацию по указаному E-mail адресу.</p>
                <p>&nbsp;</p>
                <p>6.2. Администрация обязана:</p>
                <p>&nbsp;</p>
                <p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
                <p>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. настоящей Политики Конфиденциальности.</p>
                <p>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
                <p>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>
                <p>&nbsp;</p>
                <p>7. Ответственность сторон</p>
                <p>&nbsp;</p>
                <p>7.1. Администрация, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2. и 7.2. настоящей Политики Конфиденциальности.</p>
                <p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация не несёт ответственность, если данная конфиденциальная информация:<br />7.2.1. Стала публичным достоянием до её утраты или разглашения.<br />7.2.2. Была получена от третьей стороны до момента её получения Администрацией Ресурса.<br />7.2.3. Была разглашена с согласия Пользователя.</p>
                <p>7.3. Пользователь несет полную ответственность за соблюдение требований законодательства РФ, в том числе законов о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за содержание и форму материалов.</p>
                <p>7.4. Пользователь признает, что ответственность за любую информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ как к части сайта Блог Елены Корневой, несет лицо, предоставившее такую информацию.</p>
                <p>7.5. Пользователь соглашается, что информация, предоставленная ему как часть <strong>Ресурсов&nbsp; Елены Корневой </strong>, может являться объектом интеллектуальной собственности, права на который защищены и принадлежат другим Пользователям, партнерам или рекламодателям, которые размещают такую информацию на <strong>Ресурсах&nbsp; Елены Корневой </strong>.<br />Пользователь не вправе вносить изменения, передавать в аренду, передавать на условиях займа, продавать, распространять или создавать производные работы на основе такого Содержания (полностью или в части), за исключением случаев, когда такие действия были письменно прямо разрешены собственниками такого Содержания в соответствии с условиями отдельного соглашения.</p>
                <p>7.6. В отношение текстовых материалов (статей, публикаций, находящихся в свободном публичном доступе на <strong>Ресурсах&nbsp; Елены Корневой </strong>) допускается их распространение при условии, что будет дана ссылка на <strong>Ресурсы&nbsp; Елены Корневой</strong>.</p>
                <p>7.7. Администрация не несет ответственности перед Пользователем за любой убыток или ущерб, понесенный Пользователем в результате удаления, сбоя или невозможности сохранения какого-либо Содержания и иных коммуникационных данных, содержащихся на <strong>Ресурсах&nbsp; Елены Корневой </strong>или передаваемых через него.</p>
                <p>7.8. Администрация не несет ответственности за любые прямые или косвенные убытки, произошедшие из-за: использования либо невозможности использования сайта, либо отдельных сервисов; несанкционированного доступа к коммуникациям Пользователя; заявления или поведение любого третьего лица на сайте.</p>
                <p>7.9. Администрация не несет ответственность за какую-либо информацию, размещенную пользователем на сайте Блог Елены Корневой, включая, но не ограничиваясь: информацию, защищенную авторским правом, без прямого согласия владельца авторского права.</p>
                <p>&nbsp;</p>
                <p>8. Разрешение споров</p>
                <p>&nbsp;</p>
                <p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администрацией, обязательным является предъявление претензии (письменного предложения или предложения в электронном виде о добровольном урегулировании спора).</p>
                <p>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно или в электронном виде уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
                <p>8.3. При не достижении соглашения спор будет передан на рассмотрение Арбитражного суда г. Москва.</p>
                <p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией применяется действующее законодательство Российской Федерации.</p>
                <p>&nbsp;</p>
                <p>9. Дополнительные условия</p>
                <p>&nbsp;</p>
                <p>9.1. Администрация вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
                <p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на <strong>Ресурсах Елены Корневой&nbsp;</strong> если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
                <p>9.3. Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать по адресу: <strong>info@mrk.digital</strong></p>
                <p>9.4. Действующая Политика конфиденциальности размещена на странице по адресу http://elenakorneva.ru/politika.html</p>
                <p>&nbsp;</p>
                <p>Обновлено: 03 Ноября 2023 года</p>
                <p>г. Москва, Елена Корнева (самозанятая))</p>
           </Container>
        </ThemeProvider>
    );
}
