import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import "./Citation.css"
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";


const defaultTheme = createTheme();

export default function Citation({title}) {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            {/* Hero unit */}
            <Container disableGutters  sx={{marginY: 2 }} >
                <Grid container justifyContent={"center"} display={"flex"} alignItems={"center"}>
                    <Grid item md={10} sm={10} xs={10} className={"box-citation size-common"}>
                        {title}
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
