import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import '../main/Main.css'
import Typography from "../Typography/Typography";

import ImageElena from "../../img/avatar-900.png";
import ImageRound from "../../img/round-elena.png"
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import CheckIcon from '@mui/icons-material/Check';

const topics = [
    {
        "text": ["Проблемы с животиком (газики, колики, дисбактериоз, запоры, лактазная недостаточность, АБКМ)",
            "Прикорм и трудности, связанные с ним, включая особенности прикорма при различных состояниях",
            "Сложности с пищевым поведением (малоежка)"]
    },
    {
        "text": ["Вопросы и особенности ухода за новорожденным"]
    },
    {
        "text": ["Атопический дерматит", "Аллергический дерматит"]
    },
    {
        "text": ["Малыш часто болеет", "Особенности адаптации в детском саду", "Прививки и все, что связано с вакцинацией"]
    },
    {
        "text": ["Детские истерики", "Детская агрессия (бьется головой, кусает, бросает, щипает)", "Детские капризы/нытье"]
    },
    {
        "text": ["Материнское выгорание", "Воспитание без крика"]
    },
    {
        "text": ["Подготовка и освоение навыков использования горшка", "Проблемы со сном"]
    },
    {
        "text": ["Проблемы с речью у ребенка", "Особенности \"запуска речи\" в двуязычных семьях"]
    },
    {
        "text": ["Индивидуальные особенности развития малыша",
            "Оценка физического и нервно-психического развития ребенка",
            "Особенности воспитания ребенка в цифровом мире (телефоны, планшеты, мультики)"]
    },
    {
        "text": ["И другие проблемы здровьи и развития малышей от 0 до 3 лет"]
    }
]
const services = [
    {
        "title": "Предварительный анализ документов",
        "subTitle": "<p>Перед консультацией рекомендуется предоставить:</p>\n" +
            "<ul style='padding-left: 22px'>\n" +
            "<li>Необходимые документы, такие как результаты анализов и выписки из медицинских карт.</li>\n" +
            "<li>Список вопросов, которые вас беспокоят и которые хотели бы обсудить на консультации.</li>\n" +
            "</ul>\n" +
            "<p>Это позволит ознакомиться с вашей проблемой заранее и дать более квалифицированные рекомендации в ходе консультации.</p>"
    },
    {
        "title": "Консультация в формате видеочата",
        "subTitle": "Консультация проводится в режиме видеочата через Zoom (по желанию производится видеозапись) или другого мессенжера. Длительность консультации составляет до 90 минут, что позволяет внимательно выслушать вас, задать необходимые вопросы и дать подробные рекомендации по волнующим вас вопросам"
    },
    {
        "title": "Выдача рекомендаций по итогам консультации",
        "subTitle": "В ходе консультации вы  получаете рекомендации, основанные на проведенном анализе  и обсуждении с вами проблемы. Это могут быть рекомендации по развитию ребенка, вашим действиям по укреплению здоровья, как физического, так и эмоционального. При наличии видеозаписи вы сможете ее заново пересмотреть."
    }
]
export default function FirstConsultation() {
    return (
        <Container component="section">
            <Grid container>
                <Grid item xs={12} md={5} sx={{
                    display: "flex",
                    justifyContent: {md: 'center', sm: 'center', xs: 'center'},
                    alignItems: {md: 'center', sm: 'center', xs: 'center'},
                    marginY: {md: 0, sm: 2, xs: 2},
                    flexDirection: "column"
                }}>

                    <Box
                        component="img"
                        src={ImageElena}
                        alt="Главная картинка"
                        sx={{
                            width: '100%',
                            maxWidth:420,
                            display: {md: 'block', sm: "none", xs: "none"}
                        }}
                    />
                    <Box
                        component="img"
                        src={ImageRound}
                        alt="Главная картинка круглая"
                        sx={{
                            width: '100%',
                            maxWidth: 300,
                            display: {md: 'none', sm: "block", xs: "block"}
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={7}
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: {md: 0, sm: 3, xs: 2}
                      }}>
                    <Typography className="cardTitleConsultation " sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: {md: 1, sm: 1, xs: 1},
                        fontSize: {md: 24, sm: 18, xs: 16},
                        fontWeight: 600
                    }}>
                        Удобный и эффективный способ, не выходя из дома, получить квалифицированную помощь по следующим вопросам:
                    </Typography>
                    {topics.map((item, index) => (
                            <Grid container key={index} sx={{marginBottom: 1}}>
                                <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}
                                      >
                                    <StarBorderIcon/>    <StarBorderIcon/>    <StarBorderIcon/>
                                </Grid>
                                <Grid item xs={12} display={"flex"} justifyContent={"flex-start"} alignItems={"flex-start"} flexDirection={"column"}
                                      sx={{paddingLeft: 1}} className={"size-common-price"}>
                                    {item.text.map((element, indexIn) => <Box key={indexIn}>
                                      <CheckIcon  />  {element}
                                    </Box>
                                    )
                                    }
                                </Grid>
                            </Grid>
                        )
                    )
                    }
                </Grid>

            </Grid>
            <Grid container sx={{
                display: "flex",
                justifyContent: {md: 'center', sm: 'center', xs: 'center'},
                alignItems: {md: 'center', sm: 'center', xs: 'center'},
                marginY: {md: 1, sm: 1, xs: 1},
                flexDirection: "column"
            }}>
                <Box className={"cardTitleOneConsultation size-common"} sx={{
                    textTransform: "uppercase",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginY: {md: 1, sm: 2, xs: 2},
                    textAlign: {md: "left", sm: 'center', xs: 'center'},
                }}>
                    В стоимость онлайн консультации входит:
                </Box>
                {services.map((item, index) => (
                        <Grid container key={index} sx={{marginBottom: 1}}>
                            <Grid item xs={1} display={"flex"} justifyContent={"center"} alignItems={"center"}
                                  className={"cardTitleOne size-common"}>
                                <RecentActorsIcon/>
                            </Grid>
                            <Grid item xs={11} display={"flex"} justifyContent={"flex-start"} alignItems={"center"}
                                  sx={{paddingLeft: 1}} className={"cardTitleOneConsultation size-common"}>
                                {item.title}
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"flex-start"} alignItems={"flex-start" } flexDirection={"column"}
                                  sx={{paddingLeft: 1}} className={"size-common-price"} dangerouslySetInnerHTML={{__html: item.subTitle}}>
                            </Grid>
                        </Grid>
                    )
                )
                }
            </Grid>
        </Container>
    );
}
