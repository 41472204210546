import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Box} from "@mui/system";
import Grid from "@mui/material/Grid";
import ImageElena from "../../img/avatar-900.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CheckIcon from "@mui/icons-material/Check";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ImageLeft from "./consultation-main.png";

const defaultTheme = createTheme();

export default function Important() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            {/* Hero unit */}
            <Container component="section">
                <Grid container>
                    <Grid item xs={12} md={5} sx={{
                        display: "flex",
                        justifyContent: {md: 'center', sm: 'center', xs: 'center'},
                        alignItems: {md: 'center', sm: 'center', xs: 'center'},
                        marginY: {md: 0, sm: 3, xs: 3},
                        flexDirection: "column"
                    }}>

                        <Box
                            component="img"
                            src={ImageLeft}
                            alt="Главная картинка"
                            sx={{
                                width: '100%',
                                maxWidth: 400
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: {md: 0, sm: 3, xs: 2}
                          }}>
                        <Box className={"cardTitleOne size-common"}  sx={{px:{sm: 2, sx: 2 }}}
                        >
                            Онлайн консультация, представляя собой ценный ресурс для получения информации, советов и поддержки, в
                            отдельных случаях не может полностью заменить традиционный медицинский приём.
                        </Box>
                        <Box className={"cardTitleOne size-common"}
                        >
                            В случае серьёзных заболеваний, срочных состояний или необходимости проведения обследования обратитесь за квалифицированной оффлайн помощью.
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
