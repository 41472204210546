import React, {useState, useEffect} from 'react';
import '../main/Main.css'
import LineBlock from "../SecondScreen/LineBlock"
import {Box} from "@mui/system";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DatePickerValue from "./DatePicker"
import Footer from "../Footer/Footer";




const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        // Определите, насколько экранов нужно прокрутить, чтобы кнопка стала видимой
        const scrollThreshold = window.innerHeight * 1.5;

        if (window.scrollY > scrollThreshold) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button
            style={{
                display: isVisible ? 'block' : 'none',
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                cursor: 'pointer'
            }}
            onClick={scrollToTop}
        >
            <ArrowUpwardIcon fontSize={"large"}/>
        </button>
    );
};

export default function Schedule  () {
    return (
        <Box>
            <LineBlock title={"Запись на консультацию"}/>
            <DatePickerValue/>
            <Footer/>
            <ScrollToTopButton/>
        </Box>
    );
}
