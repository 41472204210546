import * as React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Image1 from "../../img/ImageCards/card-image-gray-1.jpg";
import Image2 from "../../img/ImageCards/card-image-gray-8.jpg";
import Image3 from "../../img/ImageCards/card-image-gray-10.jpg";


const defaultTheme = createTheme();

const items = [
    {
        id: 0,
        title: "Комфортное материнство",
        subTitle: "Важная составляющая воспитания здорового ребенка. Как мама, вы знаете, что бывают моменты, когда чувства, эмоции и физические нагрузки могут стать препятствием для комфортного материнства. Помогу вам управлять эмоциями и найти решения для облегчения физических нагрузок, чтобы вы могли наслаждаться своим материнством на все 100%.",
        icon: Image2,
        order1: {md:1, sm:1, xs: 1},
        order2:  {md:2, sm:2, xs: 2},
        style: "right-border"
    },
    {
        id: 1,
        title: "Здоровье малыша",
        subTitle: "Eще один ключевой аспект родительства. Как специалист, помогу вам следить за " +
            "здоровьем малыша, объясню, какие симптомы следует обращать внимание и когда стоит обращаться за " +
            "медицинской помощью. Также могу дать рекомендации по питанию, гигиене и режиму дня, чтобы помочь " +
            "малышу расти здоровым и счастливым.",
        icon: Image1,
        order1: {md:2, sm:1, xs: 1},
        order2:  {md:1, sm:2, xs: 2},
        style: "left-border"
    },
    {
        id: 2,
        title: "Умный ребенок",
        subTitle:  "Это желание каждого родителя. Готова помочь составить индивидуальный план " +
            "развития ребенка, учитывая его природные качества, особенности семьи и общественную среду, в " +
            "которой он растет. Этот план будет включать в себя рекомендации по развитию когнитивных и " +
            "социально-эмоциональных навыков, а также по подготовке ребенка к школе.",
        icon: Image3,
        order1: {md:1, sm:1, xs: 1},
        order2: {md:2, sm:2, xs: 2},
        style: "right-border"
    }
]

export default function ElenaTeamService() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <Container disableGutters >
               { items.map((element, columnIndex) => <Grid container>
                   <Grid item md={6} sm={12} xs={12} order={element.order1} component="img" src={element.icon} alt="Image" >
                   </Grid>
                   <Grid item md={6} sm={12} xs={12} order={element.order2} container sx={{marginBottom: {md: 0, sm: 1, xs: 1}}} >
                       <Grid item  xs={12} className={"cardTitleOneConsultation size-common"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                           {element.title}
                       </Grid>
                       <Grid item  xs={12} className={"size-common-price"} display={"flex"} justifyContent={"flex-start"} alignItems={"center"} sx={{paddingLeft:2}}>
                           {element.subTitle}
                       </Grid>
                   </Grid>
                    </Grid>
                )
                }
                {/*{items.map((element, columnIndex) => (*/}
                {/*    <Grid container key={element.id} justifyContent="center" display="flex" alignItems="center" >*/}
                {/*        <Grid item md={6} sm={12} xs={12} order={element.order1} justifyContent="center" display="flex" alignItems="center" component="img" src={element.icon} alt="Image1"  sx={{ border: "0.12em solid #313131"  }} />*/}
                {/*        <Grid item md={6} sm={12} xs={12} order={element.order2} justifyContent="start" display="flex" alignItems="flex-start" sx={{ position: "relative", flexDirection: "column",borderTop: "0.12em solid #313131"  }}>*/}
                {/*            <Grid container className={"box-three-main-title"}>*/}
                {/*                <Grid item md={1} sm={1} xs={1}  justifyContent="center" display="flex" alignItems="center">*/}
                {/*                <Grid item md={10} sm={10} xs={10} justifyContent="center" display="flex" alignItems="center" className="box-three-title" >*/}
                {/*                    {element.title}*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <Grid container justifyContent="center" display="flex" alignItems="center">*/}
                {/*                <Grid item md={12} className={"box-three-subtitle"} >*/}
                {/*                    {element.subTitle}*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*))*/}
                {/*}*/}
            </Container>
        </ThemeProvider>
    );
}
