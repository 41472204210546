import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import '../main/Main.css'
import ImageLeft from "../../img/baby-smile-1.png";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Link from "@mui/material/Link";
import ListAltIcon from "@mui/icons-material/ListAlt";

const textNew = ["Экспертные советы</strong> от профессионала с более чем 30 - летним опытом развития детей.",
"Помощь в решении <strong>медицинских проблем</strong>",
"Возможность получить высококвалифицированные рекомендации, <strong>не выходя из дома</strong> и не зависимо от места жительства"
]

export default function Consultation() {
    return (
        <Container component="section">
            <Grid container>
                <Grid item xs={12} md={4} sx={{
                    display: "flex",
                    justifyContent: {md: 'flex-end', sm: 'center', xs: 'center'},
                    alignItems: {md: 'flex-end', sm: 'center', xs: 'center'},
                    marginTop: {md: 0, sm: 3, xs: 3},
                    order: {md: 1, sm: 1, xs: 1},
                    flexDirection: "column"
                }}>
                    <Box
                        component="img"
                        src={ImageLeft}
                        alt="Главная картинка"
                        sx={{
                            width: '100%',
                            maxWidth: 400
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8}
                      sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', order: {md: 2, sm: 2, xs: 2}}}>
                    {textNew.map((item, index) => (
                            <Grid container key={index} sx={{marginBottom:1}} className={"cardTitleOne size-common-price"}>
                                <Grid item xs={1} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                                    <StarBorderIcon/>
                                </Grid>
                                <Grid item xs={11}  sx={{paddingLeft:1}}
                                      dangerouslySetInnerHTML={{__html: item}}
                                >
                                </Grid>
                            </Grid>
                        )
                    )
                    }
                </Grid>

            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Link href={"/consultation"} className={"btn-call"} sx={{
                    maxWidth: 260,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center",
                    marginTop: {md: 2, sm: 2, xs: 2}
                }}>
                    <ListAltIcon color={"#666666"}/> <Box className={"top-mainSubTitle"}
                                                          sx={{marginLeft: 2}}>Перейти</Box>
                </Link>
            </Grid>
        </Container>
    );
}
